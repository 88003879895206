import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showModal } from "../../actions/actionCreators";

import PropTypes from "prop-types";

import LocalNavHardLink from "./LocalNavHardLink";
import LocalNavSoftLink from "./LocalNavSoftLink";
import LocalNavMenu from "./LocalNavMenu";
import LoginLogout from "./LoginLogout";

import { Divider } from "@material-ui/core";

export default function LeftNavigation() {
    const dispatch = useDispatch();

    //state
    const [isReportActive, setReportActive] = useState(false);
    const [isCombinedAdminActive, setCombinedAdminActive] = useState(false);
    const [isClientAdminActive, setClientAdminActive] = useState(false);
    const [isToolsActive, setToolsActive] = useState(false);
    const [isAccountActive, setAccountActive] = useState(false);

    //props
    const pathname = useSelector(state => state.router.location.pathname);
    const enabledFeatures = useSelector(state => state.session.enabledFeatures);
    const companyId = useSelector(state => state.session.companyId);
    const userName = useSelector(state => state.session.fullName);
    const userEmail = useSelector(state => state.session.userLogonId);
    const isAuthenticated = useSelector(state => state.session.isAuthenticated);
    const pwExpired = useSelector(state => state.session.pwExpired);
    const roles = useSelector(state => state.session.roles);
    const isCompanyHasManageAccess = useSelector(state => state.session.isCompanyHasManageAccess);
    const sitesAvailable = useSelector(state => state.session.sitesAvailable);

    const audienceDiscoveryName = "Audience Insights";
    const audienceDiscoverySegmentsName = "Audience Insights Segments";

    //Handle cases with non react paths for Each Navigation Link Group
    const reportPaths = ["/Tableau/Tableau3", "/LayoutsMain/History"];
    const isReport = pathname.includes("/reports/") || reportPaths.find(path => pathname.includes(path));
    const adminPaths = ["/ClientAccess/SingleViewControlCenter", "/Field", "/admin/cached-data"];
    const isCombinedAdmin = pathname.includes("/admin/") || adminPaths.find(path => pathname.includes(path));

    const isFlows = () => pathname.includes("/flows/");
    const isResourceCenterActive = () =>
        ["/help", "/partners", "/dataproviders", "/discover"].find(path => pathname.includes(path));

    //mount
    useEffect(() => {
        if (pathname) {
            setReportActive(isReport);
            setCombinedAdminActive(isCombinedAdmin);
            setClientAdminActive(pathname.includes("/admin/"));
            setToolsActive(pathname.includes("/tools/"));
            setAccountActive(pathname.includes("/account/"));
        }
    }, [pathname, isReport, isCombinedAdmin]);

    //functions
    const handleFieldLabels = () => {
        dispatch(showModal("EDIT_FIELD_LABELS"));
    };
    const handleCloneCompanyLabels = () => {
        dispatch(showModal("CLONE_COMPANY_LABELS"));
    };
    const handleOfferCodes = () => {
        dispatch(showModal("VARIABLE_ADMIN", { scope: "OfferCode" }));
    };
    const handleVariables = () => {
        dispatch(showModal("VARIABLE_ADMIN", { scope: "CampaignFlow" }));
    };
    const handleVariableGroupings = () => {
        dispatch(showModal("VARIABLE_GROUPING"));
    };
    const handleRadiusFilters = () => {
        dispatch(showModal("AUDIENCE_RADIUS"));
    };
    const handleSwitchSites = () => {
        dispatch(showModal("SITE_SWITCH"));
    };

    const handleScriptNodeSearch = () => {
        dispatch(showModal("SCRIPT_NODE_SEARCH"));
    };

    //variables
    //---roles
    const hasSuperAdmin = roles.includes("Super Admin");
    const hasExperianAdmin = roles.includes("Experian Admin");
    const hasClientAdmin = roles.includes("Client Admin");
    const showClientAdminOnly = hasClientAdmin && !hasSuperAdmin && !hasExperianAdmin;

    //---company
    const hasCompanySelected = companyId != null && companyId > 0;

    //---features
    const hasOfferCodes = enabledFeatures.includes("offer-codes");
    const hasRadiusHide = enabledFeatures.includes("radius-hide");
    const hasReportsMenu =
        enabledFeatures.includes("activity-reports") ||
        enabledFeatures.includes("tableau-reports") ||
        enabledFeatures.includes("audience-insights-reports");
    const hasVisibilityForCompany = enabledFeatures.includes("import-export-field-visibility");
    const hasClientVariablesManagement = enabledFeatures.includes("client-variables-management");
    const hasScriptNodeSearch = enabledFeatures.includes("script-node-search");

    return isAuthenticated ? (
        <div id="left-navigation" className="sidebar nav-collapsed">
            <ul className="sidebar-nav">
                <li>
                    <img className="logo" src="/Images/Icons/logo-icon.png" />
                </li>
                {!pwExpired && (
                    <>
                        {hasCompanySelected && (
                            <>
                                <LocalNavSoftLink
                                    id="my-dashboard"
                                    destination="/dashboard"
                                    text="My Dashboard"
                                    feature="dashboard-page"
                                    isCollapsed
                                    collapsedIcon="/Images/Icons/home.png"
                                />
                                <LocalNavSoftLink
                                    id="data-connect"
                                    destination="/connect"
                                    text="Data Connect"
                                    feature="data-connect"
                                    isCollapsed
                                    collapsedIcon="/Images/Icons/discover.svg"
                                />
                                <LocalNavSoftLink
                                    id="flows"
                                    destination="/flows/design"
                                    text="Flows"
                                    feature="flow"
                                    isActive={isFlows}
                                    isCollapsed
                                    collapsedIcon="/Images/Icons/primary-flow.png"
                                />
                                <LocalNavSoftLink
                                    id="make-a-wish"
                                    destination="/make-a-wish"
                                    text="Make-A-Wish"
                                    feature="make-a-wish"
                                    isCollapsed
                                    collapsedIcon="/Images/Icons/make-a-wish.png"
                                />
                            </>
                        )}
                        {hasCompanySelected && hasReportsMenu && (
                            <li id="reports" className={`menu-li ${isReportActive ? "active" : ""}`}>
                                <LocalNavMenu
                                    id="reports-menu"
                                    key="reports"
                                    text="Reports"
                                    feature={["activity-reports", "tableau-reports", "audience-insights-reports"]}
                                    featureArrayMode="OR"
                                    isCollapsed
                                    collapsedIcon="/Images/Icons/documents.png"
                                >
                                    <LocalNavSoftLink
                                        id="activity-reports"
                                        destination="/reports/activity-reports"
                                        text="Activity Reports"
                                        feature="activity-reports"
                                        removeLi={true}
                                    />
                                    <LocalNavHardLink
                                        id="discovery-reports"
                                        destination="/ae/reports/tableau-discovery-reports"
                                        text={audienceDiscoveryName}
                                        feature="audience-insights-reports"
                                        removeLi={true}
                                    />
                                    <LocalNavHardLink
                                        id="custom-reports"
                                        destination="/ae/reports/tableau-custom-reports"
                                        text="Custom Reports"
                                        feature="tableau-reports"
                                        removeLi={true}
                                    />
                                </LocalNavMenu>
                            </li>
                        )}
                        {(hasSuperAdmin || hasExperianAdmin) && (
                            <li id="admin" className={`menu-li ${isCombinedAdminActive ? "active" : ""}`}>
                                <LocalNavMenu
                                    id="combined-admin"
                                    key="combinedadmin"
                                    text="Admin"
                                    isCollapsed
                                    collapsedIcon="/Images/Icons/certified.png"
                                >
                                    <div className="nav-description" name="description">
                                        <strong>{hasSuperAdmin ? "Super Admin" : "Experian Admin"}</strong>
                                    </div>
                                    <Divider name="divider" />
                                    <LocalNavSoftLink
                                        id="user-admin"
                                        destination="/admin/users"
                                        text="Users"
                                        removeLi={true}
                                    />
                                    <LocalNavSoftLink
                                        id="companies"
                                        destination="/admin/companies"
                                        text="Companies"
                                        removeLi={true}
                                    />
                                    {hasSuperAdmin && (
                                        <LocalNavSoftLink
                                            id="destination-groups"
                                            destination="/admin/destination-groups"
                                            text="Destination Groups"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasSuperAdmin && (
                                        <LocalNavSoftLink
                                            id="migration"
                                            destination="/admin/migration"
                                            text="Migration"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasSuperAdmin && (
                                        <LocalNavSoftLink
                                            id="app-settings"
                                            destination="/admin/app-settings"
                                            text="App Settings"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasCompanySelected && <Divider name="divider" />}
                                    {hasCompanySelected && (hasSuperAdmin || hasExperianAdmin) && (
                                        <a
                                            id="preferences"
                                            href="#"
                                            onClick={() => dispatch(showModal("MANAGE_FEATURES", null))}
                                            style={{ color: "#575755", textDecoration: "none" }}
                                        >
                                            User/Company Features
                                        </a>
                                    )}
                                    {hasCompanySelected && hasSuperAdmin && (
                                        <LocalNavSoftLink
                                            id="features-2"
                                            destination="/admin/features/all-companies"
                                            text="Features (All Companies)"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasCompanySelected && hasSuperAdmin && (
                                        <LocalNavSoftLink
                                            id="clientadmin-feature-manager"
                                            destination="/admin/features/list"
                                            text="Feature Access Manager"
                                            feature="clientadmin-feature-manager"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasSuperAdmin && (
                                        <LocalNavSoftLink
                                            id="feature-audit-log"
                                            destination="/admin/feature-audit-log"
                                            text="Feature Audit Log"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasCompanySelected && <Divider name="divider" />}
                                    {hasCompanySelected && (
                                        <LocalNavHardLink
                                            id="singleview-control-center"
                                            destination="/ClientAccess/SingleViewControlCenter"
                                            text="SingleView Control Center"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasCompanySelected && isCompanyHasManageAccess && hasSuperAdmin && (
                                        <LocalNavSoftLink
                                            id="manage-access"
                                            destination="/admin/manage-access"
                                            text="Manage Access"
                                            feature="manage-access"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasCompanySelected && (
                                        <LocalNavSoftLink
                                            id="edit-fields"
                                            destination="/admin/fields/edit"
                                            text="Edit Fields"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasCompanySelected && (
                                        <LocalNavSoftLink
                                            id="edit-fields-bulk"
                                            destination="/admin/fields/bulk-actions"
                                            text="Field Bulk Management"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasVisibilityForCompany && (
                                        <LocalNavSoftLink
                                            id="edit-import-fields-bulk"
                                            destination="/admin/fields/import-bulk-actions"
                                            text="Field Visibility Bulk Management"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasCompanySelected && (
                                        <a
                                            id="edit-field-labels"
                                            href="#"
                                            onClick={handleFieldLabels}
                                            style={{ color: "#575755", textDecoration: "none" }}
                                        >
                                            Edit Field Labels
                                        </a>
                                    )}
                                    {hasCompanySelected && (
                                        <LocalNavSoftLink
                                            id="field-rates"
                                            destination="/admin/fields/rates"
                                            text="Edit Field Rates"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasCompanySelected && (
                                        <a
                                            id="edit-field-labels"
                                            href="#"
                                            onClick={handleCloneCompanyLabels}
                                            style={{ color: "#575755", textDecoration: "none" }}
                                        >
                                            Clone Field Labels and Visibility
                                        </a>
                                    )}
                                    {hasCompanySelected && (
                                        <LocalNavSoftLink
                                            id="restricted-fields"
                                            destination="/admin/restricted-fields"
                                            text="Edit Restricted Fields"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasCompanySelected && (
                                        <LocalNavSoftLink
                                            id="edit-joins"
                                            destination="/admin/joins"
                                            text="Edit Joins"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasSuperAdmin && (
                                        <LocalNavSoftLink
                                            id="update-experian-assets"
                                            destination="/admin/update-experian-assets"
                                            text="Update Experian Assets"
                                            removeLi={true}
                                        />
                                    )}
                                    {(hasSuperAdmin || hasExperianAdmin) && (
                                        <LocalNavSoftLink
                                            id="saa-advertiser-admin"
                                            destination="/admin/saa-advertisers"
                                            text="SAA Advertisers"
                                            removeLi={true}
                                        />
                                    )}
                                    <Divider name="divider" />
                                    <LocalNavSoftLink
                                        id="edit-logo"
                                        destination="/admin/image-control"
                                        text="Image Control"
                                        removeLi={true}
                                    />
                                    <LocalNavSoftLink
                                        id="distribution-list"
                                        destination="/admin/distribution-list"
                                        text="Distribution List"
                                        removeLi={true}
                                    />
                                    {hasSuperAdmin && (
                                        <LocalNavSoftLink
                                            id="security-actions"
                                            destination="/admin/security-actions"
                                            text="Security Actions"
                                            removeLi={true}
                                        />
                                    )}
                                    {(hasSuperAdmin || hasExperianAdmin) && (
                                        <LocalNavSoftLink
                                            id="log-messages"
                                            destination="/admin/log-messages"
                                            text="Log Messages"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasSuperAdmin && (
                                        <LocalNavSoftLink
                                            id="cached-data"
                                            destination="/admin/cached-data"
                                            text="Cached Data"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasSuperAdmin && (
                                        <LocalNavSoftLink
                                            id="canned-report"
                                            destination="/admin/canned-report"
                                            text="Canned Report Management"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasSuperAdmin && (
                                        <LocalNavSoftLink
                                            id="active-mq"
                                            destination="/admin/snowflake-instances"
                                            text="Snowflake Instances"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasSuperAdmin && (
                                        <LocalNavSoftLink
                                            id="deployment-destinations"
                                            destination="/admin/deployment-destinations"
                                            text="Deployment Destinations"
                                            removeLi={true}
                                        />
                                    )}
                                </LocalNavMenu>
                            </li>
                        )}
                        {showClientAdminOnly && (
                            <li id="client-admin" className={`menu-li ${isClientAdminActive ? "active" : ""}`}>
                                <LocalNavMenu
                                    id="client-admin-menu"
                                    key="clientAdmin"
                                    text="Admin"
                                    isCollapsed
                                    collapsedIcon="/Images/Icons/certified.png"
                                >
                                    <div className="nav-description" name="description">
                                        <strong>Client Admin</strong>
                                    </div>
                                    <Divider name="divider" />
                                    <LocalNavSoftLink
                                        id="user-admin"
                                        destination="/admin/users"
                                        text="Users"
                                        removeLi={true}
                                    />
                                    {hasCompanySelected && <Divider name="divider" />}
                                    {hasCompanySelected && (
                                        <LocalNavSoftLink
                                            id="image-control"
                                            destination="/admin/image-control"
                                            text="Image Control"
                                            removeLi={true}
                                        />
                                    )}
                                    {hasCompanySelected && (
                                        <LocalNavHardLink
                                            id="distribution-list"
                                            destination="/admin/distribution-list"
                                            text="Distribution List"
                                            removeLi={true}
                                        />
                                    )}
                                </LocalNavMenu>
                            </li>
                        )}
                        {hasCompanySelected && (
                            <li id="tools" className={`menu-li ${isToolsActive ? "active" : ""}`}>
                                <LocalNavMenu
                                    id="tools-menu"
                                    key="tools"
                                    text="Tools"
                                    isCollapsed
                                    collapsedIcon="/Images/Icons/tools.png"
                                >
                                    <LocalNavSoftLink
                                        id="flow-status"
                                        destination="/tools/flow-status"
                                        text="Flow Status"
                                        feature={["widget-flow-status", "flow"]}
                                        featureArrayMode="AND"
                                        removeLi={true}
                                    />
                                    <LocalNavSoftLink
                                        id="schedule-status"
                                        destination="/tools/schedule-status"
                                        text="Schedule Status"
                                        feature="widget-schedule-status"
                                        removeLi={true}
                                    />
                                    <LocalNavSoftLink
                                        id="activation-schedules-dashboard"
                                        destination="/tools/activation-schedules-dashboard"
                                        text="Activation Schedules Dashboard"
                                        feature="activation-schedules-dashboard"
                                        removeLi={true}
                                    />
                                    <LocalNavSoftLink
                                        id="calculated-fields"
                                        destination="/tools/calculated-fields"
                                        text="Calculated Fields"
                                        feature="calculated-fields"
                                        removeLi={true}
                                    />
                                    <LocalNavSoftLink
                                        id="layouts"
                                        destination="/tools/layouts"
                                        text="Layouts"
                                        feature="manage-layouts"
                                        removeLi={true}
                                    />
                                    <Divider name="divider" />
                                    <LocalNavSoftLink
                                        id="count-log"
                                        destination="/tools/count-log"
                                        text="Count Log"
                                        removeLi={true}
                                    />
                                    <LocalNavSoftLink
                                        id="deployment-queue"
                                        destination="/tools/flow-deployment-queue"
                                        text="Deployment Queue"
                                        feature="flow-deployment-queue"
                                        removeLi={true}
                                    />
                                    <Divider name="divider" />
                                    <LocalNavSoftLink
                                        id="manage-files"
                                        destination="/tools/discovery-segments"
                                        text={audienceDiscoverySegmentsName}
                                        feature="audience-insights-segments"
                                        removeLi={true}
                                    />
                                    <LocalNavSoftLink
                                        id="manage-files"
                                        destination="/tools/manage-files"
                                        text="Manage Files"
                                        feature="file-import"
                                        removeLi={true}
                                    />
                                    {(hasSuperAdmin || hasExperianAdmin) && (
                                        <LocalNavSoftLink
                                            id="manage-automation-items"
                                            destination="/tools/manage-automation-items"
                                            text="Manage Automation"
                                            removeLi={true}
                                        />
                                    )}
                                    <LocalNavSoftLink
                                        id="schedules"
                                        destination="/tools/schedules"
                                        text="Schedules"
                                        removeLi={true}
                                    />
                                    <LocalNavSoftLink
                                        id="data-dashboard"
                                        destination="/tools/data-dashboard"
                                        text="Data Dashboard"
                                        removeLi={true}
                                    />
                                    <LocalNavSoftLink
                                        id="model-status"
                                        destination="/tools/model-status"
                                        text="Model Status"
                                        feature="modeling"
                                        removeLi={true}
                                    />
                                    <Divider name="divider" />
                                    {hasOfferCodes && (
                                        <a
                                            id="offer-codes-link"
                                            href="#"
                                            onClick={handleOfferCodes}
                                            style={{ color: "#575755", textDecoration: "none" }}
                                        >
                                            Offer Codes
                                        </a>
                                    )}
                                    {(hasSuperAdmin || hasExperianAdmin || hasClientVariablesManagement) && (
                                        <a
                                            id="variables-link"
                                            href="#"
                                            onClick={handleVariables}
                                            style={{ color: "#575755", textDecoration: "none" }}
                                        >
                                            Variables
                                        </a>
                                    )}
                                    {(hasSuperAdmin || hasExperianAdmin || hasClientVariablesManagement) && (
                                        <a
                                            id="variables-link"
                                            href="#"
                                            onClick={handleVariableGroupings}
                                            style={{ color: "#575755", textDecoration: "none" }}
                                        >
                                            Variable Groupings
                                        </a>
                                    )}
                                    {hasSuperAdmin && (
                                        <LocalNavSoftLink
                                            id="edit-variable-types"
                                            destination="/tools/variable-types"
                                            text="Edit Variable Types"
                                            removeLi={true}
                                        />
                                    )}
                                    {!hasRadiusHide && (
                                        <a
                                            id="radius-hide"
                                            href="#"
                                            onClick={handleRadiusFilters}
                                            style={{ color: "#575755", textDecoration: "none" }}
                                        >
                                            Radius Filters
                                        </a>
                                    )}
                                    <LocalNavSoftLink
                                        id="flow-control-layouts"
                                        destination="/tools/layouts-external-service"
                                        text="Flow Control Layouts"
                                        feature="flow-control"
                                        removeLi={true}
                                    />
                                    <LocalNavSoftLink
                                        id="flow-control-executions-dashboard"
                                        destination="/tools/flow-control-executions-dashboard"
                                        text="Flow Control Dashboard"
                                        feature="flow-control"
                                        removeLi={true}
                                    />
                                    <LocalNavSoftLink
                                        id="flow-control-status"
                                        destination="/tools/flow-control-status"
                                        text="Flow Control Status"
                                        feature="flow-control"
                                        removeLi={true}
                                    />
                                    {hasScriptNodeSearch && (
                                        <a
                                            id="script-node-search-link"
                                            href="#"
                                            onClick={handleScriptNodeSearch}
                                            style={{ color: "#575755", textDecoration: "none" }}
                                        >
                                            Script Node Search
                                        </a>
                                    )}
                                </LocalNavMenu>
                            </li>
                        )}
                        <LocalNavSoftLink
                            id="resource-center"
                            key="resource-center"
                            destination="/help"
                            text="Resource Center"
                            isActive={isResourceCenterActive}
                            isCollapsed
                            collapsedIcon="/Images/Icons/resources.png"
                        />
                        <LocalNavSoftLink
                            id="ae-docs"
                            key="ae-docs"
                            destination="/wiki"
                            text="Documentation"
                            isCollapsed
                            collapsedIcon="/Images/Icons/wiki.svg"
                            openTab={true}
                        />
                    </>
                )}
                <li id="account" className={`menu-li account ${isAccountActive ? "active" : ""}`}>
                    <LocalNavMenu
                        id="account-menu"
                        key="account"
                        text="Account"
                        isCollapsed
                        collapsedIcon="/Images/Icons/account.png"
                    >
                        <div className="nav-description" name="description">
                            <strong>{`${userName}`}</strong>
                            <p>({userEmail})</p>
                        </div>
                        {!pwExpired && <Divider name="divider" />}
                        {!pwExpired && (
                            <LocalNavSoftLink
                                id="manage-account"
                                destination="/account/preferences"
                                text="Manage Account"
                                removeLi={true}
                            />
                        )}
                        {!pwExpired && Array.isArray(sitesAvailable) && sitesAvailable.length > 1 ? (
                            <a
                                id="switch-sites"
                                href="#"
                                onClick={handleSwitchSites}
                                style={{ color: "#575755", textDecoration: "none" }}
                            >
                                Switch Sites
                            </a>
                        ) : null}
                        <LoginLogout id="login-logout" />
                    </LocalNavMenu>
                </li>
            </ul>
        </div>
    ) : null;
}

LeftNavigation.propTypes = {
    showModal: PropTypes.func,
    requestSessionInfo: PropTypes.func,
    enabledFeatures: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string),
    isAuthenticated: PropTypes.bool,
    userEmail: PropTypes.string,
    pwExpired: PropTypes.bool,
    isCompanyHasManageAccess: PropTypes.bool,
    sitesAvailable: PropTypes.arrayOf(PropTypes.string),
    companyId: PropTypes.number,
    pathname: PropTypes.string,
};
