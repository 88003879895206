import { IApplicationUser } from "./applicationUser";
import { IFeature } from "./feature";
import { DeliveryType } from "../../enums/company";
import { FieldVisibilityTypeDefault } from "./adminDesigner";
export interface ICompany {
    CompanyID: number;
    CompanyName: string;
    CompanyActive: string;
    CompanyDateCreated: string | null;
    CompanyCreatedBy: string | null;
    CompanyDateUpdated: string | null;
    CompanyUpdatedBy: number | null;
    CompanyShortName: string;
    CompanyFolder: string;
    CompanyType: string;
    CompanyIcon: string;
    CompanyDescription: string;
    CompanyLink: string;
    CompanyContactEmail: string;
    ApproverEmail: string;
    SingleViewKeyword: string;
    CompanySortOrder: number | null;
    DeploySegmentCodeSetting: number | null;
    CompanyIsInternal: boolean;
    CompanyIsAdvertiser: boolean;
    CompanyIsDestination: boolean;
    CompanyIsDataProvider: boolean;
    CompanyIsActivationDestination: boolean;
    DefaultMinSingleViewCount: number;
    InstructionFileDestinationId: number;
    FTPFolder: string;
    CompanyBillingType: string;
    PreventOverrideDedupeExport: boolean;
    CompanyIsDedupeDefault: boolean;
    CompanyIsCustomDedupeDefault: boolean;
    CompanyIsDedupe: boolean;
    HasFlowMigrated: boolean;
    CaseBalanceIsOffDefault: boolean;
    ToCompanyAPI: boolean;
    FromCompanyAPI: boolean;
    DisplayOnProviderPage: boolean;
    DisplayOnPartnerPage: boolean;
    DatabaseEnvironmentId: number;
    DataProviderPDF: ArrayBuffer | null;
    SplitBalanceIsOffDefault: boolean;
    ExportVisibilityDefault: boolean;
    ReportVisibilityDefault: boolean;
    DefaultDedupeKey: string;
    DedupeSortByFields: string;
    RemoveFilterReportFieldLabels: boolean;
    CloudRetentionDays: number;
    ReportRetentionDays: number;
    DeploySetting?: number;
    DeliveryType: DeliveryType;
    ActivationChannelID: number;
    ParentElementId: string;
    ParentElementName: string;
    FlowScriptTableThreshold: number;
    DataRetentionDaysDefault: number;
    SortSplitOrderBy: string | null;
    CopyOfferCodes: boolean;
    CopyVariables: boolean;
    FlowControlRetentionDays: number;
    HasAdminRestrictions: boolean;
    WFClientCode: string;
    WFClientKey: number;
    DefaultParentFolderId?: number;
    EnforceDeploymentRequest: boolean;
    FieldVisibilityTypesDefault: Array<FieldVisibilityTypeDefault>;
    PrimaryKeyIsLUID: boolean;
    IsIndirectDestination: boolean;
}

export enum DeploySettings {
    DeployAdditionalFacebookTemplate = 1,
    DeployFreewheelDriverFile = 2,
    DeployPinterestAutomationTemplate = 3,
    DeployTradedeskTemplate = 4,
    DeployTikTokDriverFile = 5,
    DeploySnowflakeTable = 6,
    DeployNonExternalFields = 7,
    DeployXandrTemplate = 8,
    DeployMagniteDriverFile = 9,
}

export interface IVertical {
    VerticalId: number;
    VerticalName: string;
}

export interface ICompanyVertical {
    Company: ICompany;
    VerticalList: IVertical[];
}

export interface ICompanyView extends ICompany, ICompanyWithSeats {
    SVTableName: string;
    SVClientKey: string;
    SVDefaultMatchKey: string;
    Features: Array<IFeature>;
    ApplicationUsers: Array<IApplicationUser>;
    CreatedBy: string;
    UpdatedBy: string;
    IsAdmin: boolean;
}

export interface ICompanyTapadDeliveryType {
    TapadDeliveryTypeId: number;
    Name: string;
    ActivationChannelID: string;
    DeliveryType: number;
    ParentElementId: string;
    ParentElementName: string;
    DeploySettingId: number;
    SAAAdvertiserId: number;
}
export interface ICompanyDestinationDedupeSettings {
    Id: number;
    CompanyID: number;
    DestinationID: number;
    DestinationCName: string;
    IsDedupeDefault: boolean;
    IsCustomDedupeDefault: boolean;
    DefaultDedupeKey: string;
    DedupeSortByFields: string;
    EnableForSingleView: boolean;
}

export interface ICompanyWithSeats {
    AllowedMaximumSeats: number | null;
    UsedSeats: number | null;
    RequestedSeats: number | null;
    CompanyLogo: string;
    CompanyBase64Icon: string;
    UploadedOn: string | null;
}

export interface IImageControl {
    hasUpdated: boolean;
    logo: string;
}

export interface ICompanySeat extends ICompanyWithSeats {
    Id: number;
    CompanyId: number;
    AllowedMaximumSeats: number;
    UsedSeats: number;
    RequestedSeats: number | null;
    RequestedOn: Date | null;
    RequestedBy: number | null;
}
